<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-overlay
            variant="white"
            :show="showLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
        >
          <div class="p-1">
            <form class="" @submit.prevent="submitForm">
              <div class="row">
                <div class="form-group col-12 col-md-3">
                  <label for="phone24">Nombre:</label>
                  <b-form-input v-model="product.name" autocomplete="off"/>
                  <span v-if="validation_product.name" class="text-danger">
                                      {{ validation_product.name }}
                                    </span>
                </div>
                <div class="form-group col-12 col-md-3">
                  <label for="phone24">Observaciones:</label>
                  <b-form-input v-model="product.observations" autocomplete="off"/>
                </div>
              </div>
              <div class="row">
                <button type="submit" class="btn btn-primary col-12 col-md-1 ml-1 mt-2">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {BCard, BCol, BOverlay, BRow, BFormInput} from 'bootstrap-vue'
import {mapState} from "vuex"
import {VHE_AUXILIAR_PRODUCTS_LIST} from "@/router/web/constants"
import {createHelpers} from "vuex-map-fields"

import helper from "@/resources/helpers/helpers"

const {mapFields} = createHelpers({
  getterType: 'productsVhe/getField',
  mutationType: 'productsVhe/updateField',
})

export default {
  name: "FormView",
  components: {
    BCol,
    BRow,
    BCard,
    BFormInput,
    BOverlay,
  },
  data() {
    return {
      edit: false,
      showLoading: false,
    }
  },
  computed: {
    ...mapState('auxiliariesProductsVhe', ['validation_product']),
    ...mapFields({
      product: 'product',
    }),
  },
  created() {
    this.$store.commit('auxiliariesProductsVhe/RESET_STATE')
    this.$store.commit('auxiliariesProductsVhe/CLEAR_VALIDATION')
    if (this.$route.params.id) {
      this.edit = true
      this.getProducts()
    } else {
      this.edit = false
    }
    this.$root.$refs.auxiliariesProductsForm = this
  },
  methods: {
    getProducts() {
      this.showLoading = true

      this.$store.dispatch('productsVhe/findProducts', {
        id: this.$route.params.id ?? this.product.id
      })
          .then(response => {
            this.showLoading = false
          })
          .catch(error => {
            this.showLoading = false
          })
    },
    cancel() {
      this.$router.push(VHE_AUXILIAR_PRODUCTS_LIST)
    },
    createAuxiliar() {
      this.$store.dispatch('productsVhe/createProducts', this.product)
          .then(response => {
            helper.showToast('Producto creado correctamente!', 'CheckIcon', 'success')
            this.$router.replace(VHE_AUXILIAR_PRODUCTS_LIST)
          })
          .catch(error => {
            helper.showToast('Error al crear producto', 'AlertOctagonIcon', 'danger')
            console.log(error)
          })
    },
    updateAuxiliar() {
      this.$store.dispatch('productsVhe/updateProducts', this.product)
          .then(response => {
            helper.showToast('Producto editado correctamente!', 'CheckIcon', 'success')
            this.$router.replace(VHE_AUXILIAR_PRODUCTS_LIST)
          })
          .catch(error => {
            helper.showToast('Error al editar un producto', 'AlertOctagonIcon', 'danger')
            console.log(error)
          })
    },
    submitForm() {
      if (this.$route.params.id) {
        this.updateAuxiliar()
      } else {
        this.createAuxiliar()
      }
    },
  },
}
</script>
