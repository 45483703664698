<template>
  <div class="p-1">
    <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm">
      <b-row>
        <b-col cols="6" lg="3" md="4" sm="6">
          <b-form-group label="Nombre" label-for="name">
            <b-form-input id="name" v-model="subproduct.name" placeholder="Nombre" autocomplete="off"/>
            <span v-if="validation_subproducts.name"
                  class="text-danger">{{ validation_subproducts.name }}</span>
          </b-form-group>
        </b-col>

        <b-col cols="6" lg="3" md="4" sm="6">
          <b-form-group label="Observaciones" label-for="observations">
            <b-form-input id="observations" v-model="subproduct.observations" placeholder="Observaciones" autocomplete="off"/>
          </b-form-group>
        </b-col>



        <b-col cols="6" lg="2" md="4" sm="6">
          <b-button v-if="subproduct.id==null" variant="primary" class="mt-2 mr-1 ml-2" @click="createSubProducts">
            Guardar
          </b-button>

          <b-button v-else variant="primary" class="mt-2 mr-1 ml-2" @click="updateSubProducts">
            Editar
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>

    <b-table
        class="mt-2"
        responsive="sm"
        :items="subproducts"
        :fields="columns"
    >


      <template #cell(actions)="data" class="col-sm-2">
        <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer text-blue"
            size="16"
            @click="editSubProducts(data.item)"
        />

        <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="TrashIcon"
            size="16"
            class="cursor-pointer ml-1 text-red"
            @click="deleteSubProducts(data.item.id)"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import {BRow, BCol, BFormInput, BFormCheckbox, BFormGroup, BButton, BOverlay} from 'bootstrap-vue'
import {mapState} from "vuex"
import { BTable } from 'bootstrap-vue'

import {createHelpers} from "vuex-map-fields"
import helper from "@/resources/helpers/helpers"
import Swal from "sweetalert2"

const {mapFields} = createHelpers({
  getterType: 'productsVhe/getField',
  mutationType: 'productsVhe/updateField',
})

export default {
  name: "subproductsForm",
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BOverlay
  },
  data() {
    return {
      showLoading: false,
      columns: [
        {key: 'name', label: 'Nombre', sortable: false},
        {key: 'observations', label: 'Observaciones', sortable: false},
        {key: 'actions', label: 'Acciones', sortable: false},
      ],
      subproducts: [],
    }
  },
  computed: {
    ...mapState('auxiliariesProductsVhe', ['validation_subproducts']),
    ...mapFields({
      product: 'product',
      subproduct: 'subproduct',
    }),
  },
  created() {
    this.getSubProducts()
  },
  methods: {
    getSubProducts() {
      this.showLoading = true

      this.$store.dispatch('productsVhe/getSubProducts', {
        id: this.$route.params.id,
      })
          .then(response => {
            this.showLoading = false

            this.subproducts = response
          })
          .catch(error => {
            this.showLoading = false
            console.log(error)
          })
    },
    createSubProducts() {
      this.showLoading = true
      this.subproduct.product_id = this.$route.params.id

      this.$store.dispatch('productsVhe/createSubProducts', {
        subproduct: this.subproduct,
      })
          .then(response => {
            this.showLoading = false

            helper.showToast('SubProducto creado correctamente!', 'CheckIcon', 'success')
            this.$store.commit('productsVhe/RESET_STATE_SUBPRODUCT')
            this.getSubProducts()
          })
          .catch(error => {
            this.showLoading = false
            console.log(error)
          })
    },
    editSubProducts(subproduct){
      this.$store.commit('productsVhe/CLEAR_VALIDATION_SUBPRODUCT')
      for (const [key, value] of Object.entries(subproduct)) {
        this.subproduct[key] = value
      }
    },
    updateSubProducts(){
      this.showLoading = true
      this.subproduct.provider_id = this.$route.params.id

      this.$store.dispatch('productsVhe/updateSubProducts', {
        subproduct: this.subproduct,
      })
          .then(response => {
            this.showLoading = false

            helper.showToast('SubProducto editado correctamente!', 'CheckIcon', 'success')
            this.$store.commit('productsVhe/RESET_STATE_SUBPRODUCT')
            this.getSubProducts()
          })
          .catch(error => {
            this.showLoading = false
            console.log(error)
          })
    },
    deleteSubProducts(id){
      Swal.fire({
        title: "Estás seguro que quieres eliminar el SubProducto?",
        text: "Una vez borrado no se podra recuperar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sí, borrar!",
        cancelButtonText: "Cancelar",
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('productsVhe/deleteSubProducts', {id: id})
              .then(response => {
                this.showLoading = false
                helper.showToast('SubProducto eliminado correctamente!', 'CheckIcon', 'success')
                this.getSubProducts()
              })
              .catch(error => {
                this.showLoading = false
                helper.showToast('Error al eliminar el SubProducto', 'AlertOctagonIcon', 'danger')
                console.log(error)
              })
        }
      })
    }
  },
}
</script>
