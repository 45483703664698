<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-tabs>
          <b-tab active title="Editar Producto">
            <main-form />
          </b-tab>
          <b-tab v-if="edit" title="SubProductos">
            <contacts-form />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BCol, BOverlay, BRow, BTabs, BTab } from 'bootstrap-vue'
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import mainForm from "@/views/vhe/auxiliars_products/form/components/mainFormView"
import contactsForm from "@/views/vhe/auxiliars_products/form/components/subproductsForm"

const {mapFields} = createHelpers({
  getterType: 'auxiliariesProductsVhe/getField',
  mutationType: 'auxiliariesProductsVhe/updateField',
})

export default {
  name: "FormView",
  components: {
    BTab,
    BTabs,
    BCol,
    BRow,
    BCard,

    mainForm,
    contactsForm,
  },
  data() {
    return {
      edit: false,
    }
  },
  computed: {
    ...mapState('auxiliariesProductsVhe', ['validation_product']),
    ...mapFields({
      product: 'product',
    }),
  },
  created() {
    this.edit = !!this.$route.params.id
  },
  methods: {
  },
}
</script>
